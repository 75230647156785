body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F7F7F7;
}

.App{
  position: relative;
  /* display: flex;
  flex-direction: column; */
  /* display: grid; */
  /* grid-template-columns: 100%; */
  justify-content: center;
  align-items: center;
  height: 90vh;
  width: 100%;
}

.cell {
  padding:6px !important;
  vertical-align: middle !important;
  align-content: center;
}

.heading {
  text-align: center;
  align-self: flex-start;
  margin-bottom: 0;
}

#videos{
  /* position: relative; */
  height: 100%;
  /* display: flex;
  flex-direction: row;
  flex-wrap: wrap; */
  width: 100%;
  /* width: fit-content; */
  /* margin: auto; */
  /* align-self: flex-start; */
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  justify-content: center;
  align-items: center;
  background-color: #000;
}

.patientApp {
  width: 100%;
}

.vid{
  /* position: relative; */
  background-color:black;
  border-width: 1px;
  border-color: #38373A;
  border-style: solid;
  width: 100%;
  height: 100%;
}

.controls{
  position: absolute;
  bottom: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 1;
  width: 100%;
  margin-bottom: 14px;
}

.controls p{
  padding: 10px;
  cursor: pointer;
  background: #38373A;
  color: #F7F7F7;
  border-width: 1px;
  border-color: #F7F7F7;
  border-style: solid;
  margin:8px;
}

.controls p.on{
  background: #F7F7F7;
  color: #38373A;
  border-width: 1px;
  border-color: #F7F7F7;
  border-style: solid;
  margin:8px;
}

.join{
  position: absolute;
  z-index: 1;
  width: 30vw;
  height: fit-content;
  height: -moz-max-content;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  width: 500px;
  max-width: 75vw;
}

.join  input{
  padding: 15px;
  font-size: 1rem;
  border-width: 1px;
  border-color: #38373A;
  border-style: solid;
  width: 80%;
  display: block;
  margin:  50px auto;
}

.join  button{
  min-width: 200px;
  padding: 12px 0;
  text-align: center;
  background-color: #38373A;
  color: #F7F7F7;
  border-width: 1px;
  border-color: #F7F7F7;
  border-style: solid;
  font-size: 1rem;
  font-weight: 400;
  cursor: pointer;
  display: block;
  margin: 0 auto;
}
.my-form
{
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.my-form .row
{
    margin-left: 0;
    margin-right: 0;
}

.login-form
{
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.login-form .row
{
    margin-left: 0;
    margin-right: 0;
}

.disabled {
  pointer-events: none;
  cursor: default;
}
.border-3rem {
  border-radius:8px !important;
}
.not-have-res{
  background-color: #F3722C;
  color: #000;
}
.have-res{
  background-color: #F9C74F;
  color: #000;
}
.have-all{
  background-color: #90BE6D;
  color: #000;
}
.no-one{
  background-color:#7EA6D7;
  color: #000;
}
.disabled{
  background-color:#C4C4C4;
  color: #000;
}
.cirtical {
  background-color:#F94144;
  color: black;
}
.channel-status {
  padding:6px;
  padding-left: 8px;
  padding-right:8px;
  align-self: center;
  text-align: center;
  border-radius: 4px;
  width: fit-content;
}

.bg_dashboard {
  margin:5px;
  background-color: paleturquoise;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 1px 1px rgba(0,0,0,0.3);
}

.panel-title {
  font-weight: bolder;
  text-align: center;
  margin-top: 6px;
  color:rgb(58, 58, 58);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.panel-value {
  text-shadow: 1px 1px rgba(0,0,0,0.3);
  font-weight: 700;
  font-size: 36pt;
}